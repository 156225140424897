const PAGES = {
  CONSULTING: {
    label: 'Beratung',
    to: '/consulting/',
  },
  PARTNER: {
    label: 'Partner',
    to: '/partner/',
  },
  PROJECTS: {
    label: 'Projects',
    to: '/consulting/projects',
  },
  PODCAST: {
    label: 'Podcast',
    to: '/podcast/',
  },
  PODCAST_EPISODES: {
    label: 'Alle Ausgaben',
    to: '/podcast/episodes/',
  },
  CONTACT: {
    label: 'Kontakt',
    to: '/contact/',
  },
  PRIVACY: {
    label: 'Datenschutz',
    to: '/privacy-policy',
  },
};

export default PAGES;
